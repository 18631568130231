import DocumentTranslationService from "components/DocumentTranslationService";
import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";

const index = () => {
  return (
    <Layout>
      <SEO
        title="Certified Document Translation Services - Order Online 24/7"
        description="Fast and scalable document translation solutions for any industry. Get native translators in the medical, legal, or any technical field for 120+ languages."
      />
      <DocumentTranslationService />
    </Layout>
  );
};

export default index;
