import FirstAdComp from "components/homepage/Ads-Page/firstAdComp";
import SecondAdComp from "components/homepage/Ads-Page/secondAdComp";
import React from "react";
import OurClients from "components/LP/ourClients";
import ThirdComp from "components/LP/thirdComp";
import { NewData } from "../homepage/Ads-Page/config";
import AdContact from "components/homepage/Ads-Page/AdContact";
import SeventhAdComp from "components/homepage/websiteTranslation/seventhAdComp";
import About from "components/LP/About";
import Industries from "components/LP/Industries";
import RelativeLanguages from "components/LP/RelativeLanguages";
import Services from "components/LP/Services";
import { ServicesData } from "components/LP/data";
import ReviewSection from "components/LP/review";
import AdsCarousel from "components/homepage/Ads-Page/carosule";
import { useState } from "react";
import { DocData, DocData2 } from "components/LP/data";

const DocumentTranslationService = () => {
  const handleTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [open, setOpen] = useState(false);

  if (typeof window !== "undefined") {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }

  return (
    <>
      <FirstAdComp
        heading="Quick and Reliable Document Translation Services"
        content="Providing clients with the optimal document translation solution is our specialty. Here at Tomedes, your document will be in the hands of dedicated translation experts to ensure the quality of our work. We offer quick delivery times and are available 24/7, with highly competitive rates to meet your budget."
        FromTextColor="Get In Touch"
        FromText1="With"
        FromText2="Our Translation Company"
        fontSize="text-[16px] sm:text-[18px]"
        marginTop="sm:mt-[37px]"
        HeadfontSize="sm:text-[45px]"
        paddingY="sm:pb-10 sm:pt-0 pt-[2.5rem]"
        bottomService
        contentTwo="Providing clients with the optimal document translation solution is the heart of our service. Quick delivery, 24/7 service. Quality and expertise at competitive rates."
        hideContent
      />
      <OurClients />
      <ThirdComp
        firstHead="What’s Our"
        secondHead="Document Translation"
        thirdHead="Process?"
        contentOne="We place a premium on ensuring that your translation project is
            given the care it needs. Our account managers work with you to
            ensure that all your instructions and specifications are clearly and
            precisely conveyed to the language professionals assigned to your
            project."
        contentTwo="Our team of dedicated translators work with care and efficiency to
            deliver the best possible results, before putting the output before
            the keen eyes of a senior editor for quality assurance. And if
            that’s not enough, all our translations come with a post-project
            1-year guarantee for any quality issues."
      />
      <SecondAdComp
        midContent
        data={NewData}
        parentDivwidth
        customWidth="max-w-[300px]"
        newCarousel
        headTitle="Why Translate With Us?"
        fontBold
        title="Why Translate With Us?"
        gapX
        noPadding
      />
      <AdContact
        open={open}
        setOpen={setOpen}
        head="Ready to get started?"
        paddingY="pt-14 sm:pb-10"
        mobileContactButton
      />
      <SeventhAdComp
        serviceSection
        content=" Tomedes is recognized by the International Organization for Standards,
            the foremost authority on industry standards in the world. 
            The company is ISO-certified in three categories relevant to translation services:"
        head="Accredited by ISO for Exceptional Standards"
      />
      <RelativeLanguages open={open} setOpen={setOpen} />
      <Industries
        open={open}
        setOpen={setOpen}
        head="Document Translation Services For Any Industry"
      />
      <ReviewSection
        open={open}
        setOpen={setOpen}
        heading="A Wide Portfolio Of Document Translation Solutions"
        data={DocData}
        data2={DocData2}
      />
      <SecondAdComp noContent BottomSection NotshowCarousel />
      <About />
    </>
  );
};

export default DocumentTranslationService;
